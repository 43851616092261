<template>
  <header>
    <div class="container">
      <div class="header-left">
        <div class="logo">
          <a href="/">
            <img :src="logoSrc" alt="Recovery Assist">
          </a>
        </div>
      </div>
      <div class="header-right">
        <nav :class="isMenuOpen ? 'menu-open' : 'menu-closed'" @click.self="closeMenu">
          <ul>
            <li><a href="#home" @click.prevent="handleMenuClick('#home')">Home</a></li>
            <li><a href="#works_process" @click.prevent="handleMenuClick('#works_process')">How Our Process Works</a></li>
            <li><a href="#type_of_scams" @click.prevent="handleMenuClick('#type_of_scams')">Types of Scams</a></li>
            <li><a href="#our_teams" @click.prevent="handleMenuClick('#our_teams')">Our Team</a></li>
            <li><a href="#mission" @click.prevent="handleMenuClick('#mission')">Mission</a></li>
            <li><a href="#testimonials" @click.prevent="handleMenuClick('#testimonials')">Client Testimonials</a></li>
            <li><a href="#faq" @click.prevent="handleMenuClick('#faq')">FAQ</a></li>
            <li><a href="#contacts" @click.prevent="handleMenuClick('#contacts')">Contact Us</a></li>
          </ul>
        </nav>
        <button class="navbar-toggler" @click="toggleMenu">
          <span class="hamburger-toggle"></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      logoSrc: require('@/img/logo.png'),
      isMenuOpen: false, 
    };
  },
  methods: {
    toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    const navbarToggler = this.$el.querySelector('.navbar-toggler');
    
    if (navbarToggler) {
      if (this.isMenuOpen) {
        navbarToggler.classList.add('active');
      } else {
        navbarToggler.classList.remove('active');
      }
    }
  },
  closeMenu() {
    this.isMenuOpen = false;
    const navbarToggler = this.$el.querySelector('.navbar-toggler');
    
    if (navbarToggler) {
      navbarToggler.classList.remove('active');
    }
  },
    handleMenuClick(target) {
      this.scrollTo(target);
      this.closeMenu();
    },
    scrollTo(target) {
      const element = document.querySelector(target);
      if (element) {
        const offset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },
    closeMenuOnOutsideClick(event) {
      const headerRight = this.$el.querySelector('.header-right');
      if (!headerRight.contains(event.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenuOnOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenuOnOutsideClick);
  }
};
</script>

<style>

.navbar-toggler {
    display: none;
    background: none;
    border: 0;
    width: 24px;
    height: 20px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid var(--color-very-dark-blue);
    transition: all 300ms linear;
}

.navbar-toggler:after, .navbar-toggler:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: var(--color-very-dark-blue);
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

.navbar-toggler:after {
    top: 9px;
}

.navbar-toggler.active {
    border-color: transparent;
}

.navbar-toggler.active:before {
    transform: translateY(9px) rotate(-45deg);
}

.navbar-toggler.active:after {
    transform: rotate(45deg);
}
</style>
