<template>
    <!-- SECTION-HERO -->
    <section class="hero" id="mission">
      <div class="container">
        <div class="heading-text">
          <h1>Our Mission</h1>
          <p>{{ mission.p1 }}</p>
          <p>{{ mission.p2 }}</p>
          <p>{{ mission.p3 }}</p>
          <p>{{ mission.p4 }}</p>
        </div>
        <div class="heading-img">
          <img :src="mainSrc" alt="Main Illustration">
        </div>
      </div>
    </section>
    <!-- END SECTION-HERO -->
  </template>
  
  <script>
  // import axios from 'axios';
  
  export default {
    name: 'MissionComponent',
    data() {
      return {
        mission: {},
        mainSrc: require('@/assets/img/main.svg')
      };
    },
    mounted() {
      this.fetchMission();
    },
    methods: {
    fetchMission() {
      // Використовуйте статичні дані замість запиту до API
      this.mission = {
        "id": 1,
        "p1": "Our mission at Recovery Assist is straightforward: to empower victims and combat fraudulent financial activities. We are dedicated to restoring justice for those affected by leveraging a comprehensive strategy that combines legal expertise, advanced technology, and a global network of professionals.",
        "p2": "We take pride in our ability to track financial and cryptocurrency transactions, thanks to our extensive experience in the legal field and established connections with leading financial organizations. These advantages enable us to obtain information about illicit activities and facilitate the recovery of stolen assets.",
        "p3": "Our operations have a global reach, and we actively employ cutting-edge developments in cryptography and information technology. This allows us to effectively monitor transactions and, when necessary, recover funds for their rightful owners.",
        "p4": "We strive to ensure the lawful return of funds to their legitimate owners while upholding all necessary legal standards."
      };
      console.log('Static mission data:', this.mission);
    }
  }}
  </script>
  