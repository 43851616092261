<template>
  <div id="app">
    <HeaderComponent />
    <HeroComponent />
    <ProcessComponent />
    <TypeOfScumComponent />
    <MissionComponent />
    <OurTeamComponent />
    <TestimonialsComponent />
    <FaqComponent />
    <ContactsComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue';
import HeroComponent from './components/Hero.vue';
import ProcessComponent from './components/Process.vue';
import FooterComponent from './components/Footer.vue';
import MissionComponent from './components/Mission.vue';
import TypeOfScumComponent from './components/TypeOfScum.vue';
import OurTeamComponent from './components/OurTeam.vue';
import TestimonialsComponent from './components/Testimonials.vue';
import FaqComponent from './components/Faq.vue';
import ContactsComponent from './components/Contacts.vue';



export default {
  name: 'App',
  components: {
    HeaderComponent,
    HeroComponent,
    ProcessComponent,
    FooterComponent,
    MissionComponent,
    OurTeamComponent,
    TypeOfScumComponent,
    TestimonialsComponent,
    FaqComponent,
    ContactsComponent,
  },
}
</script>

<style>
@import '~@/assets/css/style.css';
@import '~@/assets/css/media.css';
</style>
