<template>
  <section class="faq-section" id="faq">
    <div class="container">
      <div class="section-title">
        <h2>
          FREQUENTLY ASKED 
          <span>QUESTIONS</span>
        </h2>
        <div class="subtitle">
          To better understand how Recovery Assist operates and the systems we have in place for fund recovery, please refer to the frequently asked questions below. If you have any additional questions or need further assistance, don't hesitate to contact Recovery Assist. We are here to help you recover your funds and combat financial fraud.
        </div>
      </div>
      <div class="faq-list">
        <div
          class="faq-item"
          v-for="(faq, index) in faqs"
          :key="faq.id"
          @click="toggleFAQ(index)"
        >
          <div class="faq-question">
            <h3>{{ faq.title }}</h3>
            <span class="arrow" :class="{ open: faq.open }">></span>
          </div>
          <div class="faq-answer" v-if="faq.open">
            <p>{{ faq.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';

export default {
  name: "FAQSection",
  data() {
    return {
      faqs: [],
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.fetchFAQs();
  },
  methods: {
    // async fetchFAQs() {
    //   this.isLoading = true;
    //   try {
    //     const response = await axios.get('http://127.0.0.1:8000/api/faq/');
    //     this.faqs = response.data.map(faq => ({
    //       ...faq,
    //       open: false,
    //     }));
    //   } catch (error) {
    //     console.error('Error fetching FAQs:', error);
    //     this.error = 'Failed to load FAQs. Please try again later.';
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },
    fetchFAQs() {
      // Використовуйте статичні дані замість запиту до API
      this.isLoading = false; // Оскільки дані вже статичні, завантаження не потрібно
      this.error = null; // Скидаємо будь-які помилки
      this.faqs = [
        {
          "id": 1,
          "title": "Who are we?",
          "text": "Recovery Assist specializes in investigating fraud and financial crimes. Our certified investigators collect crucial evidence, prepare detailed reports, and offer extensive support for litigation, prosecution, settlement negotiations, and financial recovery. We serve a diverse client base, including law firms, corporations, government agencies, investors, and individuals, and operate globally, working closely with clients both domestically and internationally."
        },
        {
          "id": 2,
          "title": "How can I determine if I am eligible for funds recovery?",
          "text": "Eligibility for funds recovery depends on various factors such as the nature of the scam, jurisdiction, and specific circumstances. To determine your eligibility, it's best to consult with our team for a personalized assessment based on your case."
        },
        {
          "id": 3,
          "title": "What types of scams can be eligible for funds recovery?",
          "text": "Funds recovery can be pursued for numerous types of scams, including cryptocurrency scams, investment fraud, binary options scams, Ponzi schemes, hacks, and more. Each case is unique, and our team will review the specifics to assess the viability of recovery."
        },
        {
          "id": 4,
          "title": "What information should I provide during the consultation?",
          "text": "During the consultation, providing relevant documentation, transaction records, communication with the scammer, and any other evidence related to the scam will help us understand your case better and develop an effective strategy."
        },
        {
          "id": 5,
          "title": "How long does the funds recovery process typically take?",
          "text": "The time required for the funds recovery process varies based on factors like the complexity of the case, cooperation from involved parties, and legal procedures. We aim to expedite the process and recover your funds as efficiently as possible."
        },
        {
          "id": 6,
          "title": "Is there a guarantee of successful funds recovery?",
          "text": "While we strive to maximize the chances of successful recovery, it is important to note that there are inherent risks and uncertainties involved in funds recovery cases. We cannot provide a guarantee of successful recovery, but we employ our expertise and resources to pursue the best possible outcome for your case."
        },
        {
          "id": 7,
          "title": "Can I handle the funds recovery process on my own?",
          "text": "While it is technically possible to pursue funds recovery independently, it is important to consider the challenges involved. Dealing with scams and fraud requires specialized expertise, access to costly tools, and knowledge of the legal landscape. Engaging professional assistance significantly enhances your chances of success. Our team possesses the necessary resources, industry connections, and experience to navigate the complexities of funds recovery, advocate for your best interests, and increase the likelihood of a favorable outcome."
        },
        {
          "id": 8,
          "title": "Will my case be kept confidential?",
          "text": "Yes, we prioritize client confidentiality and handle all cases with the utmost discretion. Your personal information and the details of your case will be treated with strict confidentiality."
        }
      ];
    },
    toggleFAQ(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
};
</script>

<style scoped>
.faq-section {
  padding: 40px 0;
  background-color: var(--color-snow-gray);
}
@media (max-width: 540px) {
  .faq-section {
    padding: 25px 0;
  }
}

.section-title {
  margin-bottom: 40px;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 20px;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
@media (max-width: 540px) {
  .faq-question h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }
}

.faq-answer {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}
@media (max-width: 540px) {
  .faq-answer {
    font-size: 0.9rem;
  }
}

.arrow {
  transition: transform 0.3s ease;
  font-size: 24px;
  line-height: 1;
  transform: rotate(0deg);
}
.arrow.open {
  transform: rotate(90deg);
}
</style>
