<template>
  <footer>
    <div class="container">
      <div class="footer-links">
        <div class="footer-links-left">
          <div class="secured">
            <div class="secured-img">
              <img :src="pci" alt="PCI">
            </div>
            <div class="secured-img">
              <img :src="ssl" alt="SSL">
            </div>
          </div>
        </div>
        <div class="links">
          <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#works_process">How Our Process Works</a></li>
              <li><a href="#type_of_scams">Types of Scams</a></li>
              <li><a href="#our_teams">Our Team</a></li>
              <li><a href="#mission">Mission</a></li>
              <li><a href="#testimonials">Client Testimonials</a></li>
              <li><a href="#faq">FAQ</a></li>
              <li><a href="#contacts">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-divider"></div>
      <div class="footer-text">
        <p>Disclaimer: Recovery Assist does not request payment to directly obtain funds or facilitate recovery. We operate exclusively as a cyber research and consultancy firm. This means that our company does not offer claim management, legal services, investment services, or asset recovery. Our role is to conduct research to support scam cases and provide intelligence that can aid in pursuing the perpetrators, but we do not guarantee the recovery of funds.</p>
        <p>At Recovery Assist, we do not engage in unsolicited calls. We initiate communication only in response to clients who have contacted us.</p>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-left">
          <p class="copyrights">&copy; 2022 All rights reserved.</p>
        </div>
    
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
      return {
        logoSrc: require('@/img/logo.png'),
        pci: require('@/img/pci.png'),
        ssl: require('@/img/ssl.png')
      };
    }
  }
</script>

