<template>
  <!-- SECTION-HERO -->
  <section class="hero" id="home">
    <div class="container">
      <div class="heading-text">
        <h2>{{ heroData.title }}</h2>
        <p>{{ heroData.text }}</p>
      </div>
      <div class="heading-form">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">Name</label>
            <input 
              type="text" 
              id="name" 
              v-model="formData.name" 
              @blur="validateName" 
              :class="{ 'is-invalid': errors.name }"
              required 
            />
            <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
          </div>

          <div class="form-group">
            <label for="phone">Phone</label>
            <input 
              type="tel" 
              id="phone" 
              ref="phoneInput"
              v-model="formData.phone" 
              @blur="validatePhone" 
              :class="{ 'is-invalid': errors.phone }"
              required 
            />
            <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input 
              type="email" 
              id="email" 
              v-model="formData.email" 
              @blur="validateEmail" 
              :class="{ 'is-invalid': errors.email }"
              required 
            />
            <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
          </div>

          <div class="form-group">
            <label for="case">Tell us about your case</label>
            <textarea 
              id="case" 
              v-model="formData.case" 
              @blur="validateCase" 
              :class="{ 'is-invalid': errors.case }"
              rows="4" 
              required
            ></textarea>
            <div v-if="errors.case" class="error-message">{{ errors.case }}</div>
          </div>

          <div class="tab-button">
            <button type="submit" class="cta">Submit</button>
          </div>
        </form>

        <!-- Success and Error Messages -->
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
    <!-- Payment Icons -->
    <div class="payment-icons">
      <img :src="Visa" alt="Visa">
      <img :src="Master_Card" alt="MasterCard">
      <img :src="Binance" alt="Binance">
      <img :src="Kraken" alt="Kraken">
      <img :src="Bybit" alt="Bybit">
      <img :src="Coinbase" alt="Coinbase">
      <img :src="OKX" alt="OKX">
      <img :src="HTX" alt="HTX">
    </div>
  </section>
  <!-- END SECTION-HERO -->
</template>

<script>
import emailjs from 'emailjs-com';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axios from 'axios'; // Для отримання IP-локації

export default {
  name: 'HeroComponent',
  data() {
    return {
      heroData: { title: '', text: '' }, 
      formData: { 
        name: '', 
        phone: '', 
        email: '', 
        case: '' 
      },
      errors: {},
      successMessage: '',
      errorMessage: '',
      Visa: require('@/img/payment/visa.png'),
      Master_Card: require('@/img/payment/master_card.png'),
      Binance: require('@/img/payment/binance.png'),
      Kraken: require('@/img/payment/kraken.png'),
      Bybit: require('@/img/payment/bybit.svg'),
      Coinbase: require('@/img/payment/coinbase.png'),
      OKX: require('@/img/payment/okx.png'),
      HTX: require('@/img/payment/htx.png'),
    };
  },
  mounted() {
    this.heroData = {
      "id": 1,
      "title": "HELPING YOU RECLAIM YOUR MONEY FROM SCAMS WITH RECOVERY ASSIST",
      "text": "At Recovery Assist, we understand the distress and frustration that come with falling victim to online fraud. Our mission is to assist individuals who have been scammed in reclaiming their hard-earned money. With a focus on trustworthiness and effective communication, we are dedicated to providing reliable legal solutions for our clients."
    };
    console.log('Static hero data:', this.heroData);
    const input = this.$refs.phoneInput;
    if (input){
            // Завантажуємо телефонний інпут із можливістю вибору коду країни
        intlTelInput.loadUtils("https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js")
        .then(() => {
          this.iti = intlTelInput(input, {
            separateDialCode: true, // Показувати лише код країни окремо
            initialCountry: "auto", // Вибрати країну за замовчуванням
            geoIpLookup: this.lookupIPLocation, // Виклик функції для визначення країни за IP
            utilsScript: "../node_modules/intl-tel-input/build/js/utils.js"
          });
          input.addEventListener('countrychange', this.onCountryChange);
        })
        .catch(error => {
          console.error('Error loading utils.js:', error);
        });
    }
  },
  methods: {
    lookupIPLocation(callback) {
      axios.get('https://ipapi.co/json/') // Запит до стороннього API для отримання локації
        .then(response => {
          const countryCode = response.data.country_code;
          callback(countryCode); // Визначаємо код країни на основі IP
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          callback('us'); // Значення за замовчуванням
        });
    },
    onCountryChange() {
      const selectedCountryData = this.iti.getSelectedCountryData();
      console.log('Selected country code:', selectedCountryData.dialCode);
    },

    validateName() {
      this.errors.name = this.formData.name ? '' : 'Name is required';
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors.email = emailRegex.test(this.formData.email) ? '' : 'Invalid email address';
    },
    validateCase() {
      this.errors.case = this.formData.case ? '' : 'Message is required';
    },
    submitForm() {
      this.validateName();
      this.validateEmail();
      this.validateCase();


      if (Object.values(this.errors).every(error => !error)) {
        // Отримання інформації про браузер і платформу
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;

        // Дефолтна заглушка для локації
        let locationInfo = 'Location not provided';

        // Отримання інформації про місцезнаходження (якщо дозволено)
        navigator.geolocation.getCurrentPosition(position => {
          locationInfo = `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`;

          // Дані для відправки
          const templateParams = {
            name: this.formData.name,
            email: this.formData.email,
            countryCode: this.iti.getSelectedCountryData().dialCode,
            phone: this.formData.phone,
            case: this.formData.case,
            userAgent: userAgent,
            platform: platform,
            language: language,
            location: locationInfo
          };

          this.sendForm(templateParams); // Викликаємо sendForm
        }, error => {
          console.warn('Location access denied or error getting location:', error);

          // Якщо користувач відмовив у доступі до локації
          const templateParams = {
            name: this.formData.name,
            email: this.formData.email,
            countryCode: this.iti.getSelectedCountryData().dialCode,
            phone: this.formData.phone,
            case: this.formData.case,
            userAgent: userAgent,
            platform: platform,
            language: language,
            location: locationInfo // Заглушка для локації
          };

          this.sendForm(templateParams); // Викликаємо sendForm
        });
      } else {
        this.successMessage = '';
        this.errorMessage = 'Please correct the errors in the form before submitting.';
      }
    },
    sendForm(templateParams) {
      console.log(this.formData);
      console.log(templateParams);
      this.successMessage = 'Your form has been submitted successfully!';
      emailjs.send('service_8cp3tya', 'template_ulidzha', templateParams, 'ziMoR2JSykXK6Lmmj')
        .then(response => {
          console.log('Form submitted successfully:', response);
          this.formData = { name: '', phone: '', email: '', case: '' };
          this.successMessage = 'Your form has been submitted successfully!';
          this.errorMessage = '';
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          this.successMessage = '';
          this.errorMessage = 'There was an error submitting your form. Please try again.';
        });
    }
  }
};
</script>


<style scoped>
.form-group {
  margin-bottom: 1rem;
}
input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input.is-invalid, textarea.is-invalid {
  border-color: #dc3545;
}
.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.success-message {
  color: #28a745;
  font-size: 1rem;
  margin-top: 1rem;
}
.payment-icons {
  margin: 100px 100px 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.payment-icons img {
  width: auto;
  flex: 1;
  max-width: 100px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .payment-icons {
    justify-content: center;
    margin: 0 50px;
  }

  .payment-icons img {
    max-width: 80px; /* Adjust as needed */
  }
}
</style>
{
  "id": 0,
  "telInput": {},
  "highlightedItem": null,
  "options": {
      "allowDropdown": true,
      "autoPlaceholder": "polite",
      "containerClass": "",
      "countryOrder": null,
      "countrySearch": true,
      "customPlaceholder": null,
      "dropdownContainer": null,
      "excludeCountries": [],
      "fixDropdownWidth": true,
      "formatAsYouType": true,
      "formatOnDisplay": true,
      "hiddenInput": null,
      "i18n": {
          "ad": "Andorra",
          "ae": "United Arab Emirates",
          "af": "Afghanistan",
          "ag": "Antigua & Barbuda",
          "ai": "Anguilla",
          "al": "Albania",
          "am": "Armenia",
          "ao": "Angola",
          "ar": "Argentina",
          "as": "American Samoa",
          "at": "Austria",
          "au": "Australia",
          "aw": "Aruba",
          "ax": "Åland Islands",
          "az": "Azerbaijan",
          "ba": "Bosnia & Herzegovina",
          "bb": "Barbados",
          "bd": "Bangladesh",
          "be": "Belgium",
          "bf": "Burkina Faso",
          "bg": "Bulgaria",
          "bh": "Bahrain",
          "bi": "Burundi",
          "bj": "Benin",
          "bl": "St. Barthélemy",
          "bm": "Bermuda",
          "bn": "Brunei",
          "bo": "Bolivia",
          "bq": "Caribbean Netherlands",
          "br": "Brazil",
          "bs": "Bahamas",
          "bt": "Bhutan",
          "bw": "Botswana",
          "by": "Belarus",
          "bz": "Belize",
          "ca": "Canada",
          "cc": "Cocos (Keeling) Islands",
          "cd": "Congo - Kinshasa",
          "cf": "Central African Republic",
          "cg": "Congo - Brazzaville",
          "ch": "Switzerland",
          "ci": "Côte d’Ivoire",
          "ck": "Cook Islands",
          "cl": "Chile",
          "cm": "Cameroon",
          "cn": "China",
          "co": "Colombia",
          "cr": "Costa Rica",
          "cu": "Cuba",
          "cv": "Cape Verde",
          "cw": "Curaçao",
          "cx": "Christmas Island",
          "cy": "Cyprus",
          "cz": "Czechia",
          "de": "Germany",
          "dj": "Djibouti",
          "dk": "Denmark",
          "dm": "Dominica",
          "do": "Dominican Republic",
          "dz": "Algeria",
          "ec": "Ecuador",
          "ee": "Estonia",
          "eg": "Egypt",
          "eh": "Western Sahara",
          "er": "Eritrea",
          "es": "Spain",
          "et": "Ethiopia",
          "fi": "Finland",
          "fj": "Fiji",
          "fk": "Falkland Islands",
          "fm": "Micronesia",
          "fo": "Faroe Islands",
          "fr": "France",
          "ga": "Gabon",
          "gb": "United Kingdom",
          "gd": "Grenada",
          "ge": "Georgia",
          "gf": "French Guiana",
          "gg": "Guernsey",
          "gh": "Ghana",
          "gi": "Gibraltar",
          "gl": "Greenland",
          "gm": "Gambia",
          "gn": "Guinea",
          "gp": "Guadeloupe",
          "gq": "Equatorial Guinea",
          "gr": "Greece",
          "gt": "Guatemala",
          "gu": "Guam",
          "gw": "Guinea-Bissau",
          "gy": "Guyana",
          "hk": "Hong Kong SAR China",
          "hn": "Honduras",
          "hr": "Croatia",
          "ht": "Haiti",
          "hu": "Hungary",
          "id": "Indonesia",
          "ie": "Ireland",
          "il": "Israel",
          "im": "Isle of Man",
          "in": "India",
          "io": "British Indian Ocean Territory",
          "iq": "Iraq",
          "ir": "Iran",
          "is": "Iceland",
          "it": "Italy",
          "je": "Jersey",
          "jm": "Jamaica",
          "jo": "Jordan",
          "jp": "Japan",
          "ke": "Kenya",
          "kg": "Kyrgyzstan",
          "kh": "Cambodia",
          "ki": "Kiribati",
          "km": "Comoros",
          "kn": "St. Kitts & Nevis",
          "kp": "North Korea",
          "kr": "South Korea",
          "kw": "Kuwait",
          "ky": "Cayman Islands",
          "kz": "Kazakhstan",
          "la": "Laos",
          "lb": "Lebanon",
          "lc": "St. Lucia",
          "li": "Liechtenstein",
          "lk": "Sri Lanka",
          "lr": "Liberia",
          "ls": "Lesotho",
          "lt": "Lithuania",
          "lu": "Luxembourg",
          "lv": "Latvia",
          "ly": "Libya",
          "ma": "Morocco",
          "mc": "Monaco",
          "md": "Moldova",
          "me": "Montenegro",
          "mf": "St. Martin",
          "mg": "Madagascar",
          "mh": "Marshall Islands",
          "mk": "North Macedonia",
          "ml": "Mali",
          "mm": "Myanmar (Burma)",
          "mn": "Mongolia",
          "mo": "Macao SAR China",
          "mp": "Northern Mariana Islands",
          "mq": "Martinique",
          "mr": "Mauritania",
          "ms": "Montserrat",
          "mt": "Malta",
          "mu": "Mauritius",
          "mv": "Maldives",
          "mw": "Malawi",
          "mx": "Mexico",
          "my": "Malaysia",
          "mz": "Mozambique",
          "na": "Namibia",
          "nc": "New Caledonia",
          "ne": "Niger",
          "nf": "Norfolk Island",
          "ng": "Nigeria",
          "ni": "Nicaragua",
          "nl": "Netherlands",
          "no": "Norway",
          "np": "Nepal",
          "nr": "Nauru",
          "nu": "Niue",
          "nz": "New Zealand",
          "om": "Oman",
          "pa": "Panama",
          "pe": "Peru",
          "pf": "French Polynesia",
          "pg": "Papua New Guinea",
          "ph": "Philippines",
          "pk": "Pakistan",
          "pl": "Poland",
          "pm": "St. Pierre & Miquelon",
          "pr": "Puerto Rico",
          "ps": "Palestinian Territories",
          "pt": "Portugal",
          "pw": "Palau",
          "py": "Paraguay",
          "qa": "Qatar",
          "re": "Réunion",
          "ro": "Romania",
          "rs": "Serbia",
          "ru": "Russia",
          "rw": "Rwanda",
          "sa": "Saudi Arabia",
          "sb": "Solomon Islands",
          "sc": "Seychelles",
          "sd": "Sudan",
          "se": "Sweden",
          "sg": "Singapore",
          "sh": "St. Helena",
          "si": "Slovenia",
          "sj": "Svalbard & Jan Mayen",
          "sk": "Slovakia",
          "sl": "Sierra Leone",
          "sm": "San Marino",
          "sn": "Senegal",
          "so": "Somalia",
          "sr": "Suriname",
          "ss": "South Sudan",
          "st": "São Tomé & Príncipe",
          "sv": "El Salvador",
          "sx": "Sint Maarten",
          "sy": "Syria",
          "sz": "Eswatini",
          "tc": "Turks & Caicos Islands",
          "td": "Chad",
          "tg": "Togo",
          "th": "Thailand",
          "tj": "Tajikistan",
          "tk": "Tokelau",
          "tl": "Timor-Leste",
          "tm": "Turkmenistan",
          "tn": "Tunisia",
          "to": "Tonga",
          "tr": "Turkey",
          "tt": "Trinidad & Tobago",
          "tv": "Tuvalu",
          "tw": "Taiwan",
          "tz": "Tanzania",
          "ua": "Ukraine",
          "ug": "Uganda",
          "us": "United States",
          "uy": "Uruguay",
          "uz": "Uzbekistan",
          "va": "Vatican City",
          "vc": "St. Vincent & Grenadines",
          "ve": "Venezuela",
          "vg": "British Virgin Islands",
          "vi": "U.S. Virgin Islands",
          "vn": "Vietnam",
          "vu": "Vanuatu",
          "wf": "Wallis & Futuna",
          "ws": "Samoa",
          "ye": "Yemen",
          "yt": "Mayotte",
          "za": "South Africa",
          "zm": "Zambia",
          "zw": "Zimbabwe",
          "selectedCountryAriaLabel": "Selected country",
          "noCountrySelected": "No country selected",
          "countryListAriaLabel": "List of countries",
          "searchPlaceholder": "Search",
          "zeroSearchResults": "No results found",
          "oneSearchResult": "1 result found",
          "multipleSearchResults": "${count} results found",
          "ac": "Ascension Island",
          "xk": "Kosovo"
      },
      "initialCountry": "auto",
      "nationalMode": false,
      "onlyCountries": [],
      "placeholderNumberType": "MOBILE",
      "showFlags": true,
      "separateDialCode": true,
      "strictMode": false,
      "useFullscreenPopup": false,
      "utilsScript": "../node_modules/intl-tel-input/build/js/utils.js",
      "validationNumberType": "MOBILE"
  },
  "hadInitialPlaceholder": false,
  "isAndroid": false,
  "isRTL": false,
  "showSelectedCountryOnLeft": true,
  "originalPaddingLeft": "",
  "promise": {},
  "selectedCountryData": {
      "name": "Ukraine",
      "iso2": "ua",
      "dialCode": "380",
      "priority": 0,
      "areaCodes": null,
      "nodeById": {
          "0": {}
      }
  },
  "countries": [
      {
          "name": "Afghanistan",
          "iso2": "af",
          "dialCode": "93",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Åland Islands",
          "iso2": "ax",
          "dialCode": "358",
          "priority": 1,
          "areaCodes": [
              "18"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Albania",
          "iso2": "al",
          "dialCode": "355",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Algeria",
          "iso2": "dz",
          "dialCode": "213",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "American Samoa",
          "iso2": "as",
          "dialCode": "1",
          "priority": 5,
          "areaCodes": [
              "684"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Andorra",
          "iso2": "ad",
          "dialCode": "376",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Angola",
          "iso2": "ao",
          "dialCode": "244",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Anguilla",
          "iso2": "ai",
          "dialCode": "1",
          "priority": 6,
          "areaCodes": [
              "264"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Antigua & Barbuda",
          "iso2": "ag",
          "dialCode": "1",
          "priority": 7,
          "areaCodes": [
              "268"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Argentina",
          "iso2": "ar",
          "dialCode": "54",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Armenia",
          "iso2": "am",
          "dialCode": "374",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Aruba",
          "iso2": "aw",
          "dialCode": "297",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ascension Island",
          "iso2": "ac",
          "dialCode": "247",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Australia",
          "iso2": "au",
          "dialCode": "61",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Austria",
          "iso2": "at",
          "dialCode": "43",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Azerbaijan",
          "iso2": "az",
          "dialCode": "994",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bahamas",
          "iso2": "bs",
          "dialCode": "1",
          "priority": 8,
          "areaCodes": [
              "242"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bahrain",
          "iso2": "bh",
          "dialCode": "973",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bangladesh",
          "iso2": "bd",
          "dialCode": "880",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Barbados",
          "iso2": "bb",
          "dialCode": "1",
          "priority": 9,
          "areaCodes": [
              "246"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Belarus",
          "iso2": "by",
          "dialCode": "375",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Belgium",
          "iso2": "be",
          "dialCode": "32",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Belize",
          "iso2": "bz",
          "dialCode": "501",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Benin",
          "iso2": "bj",
          "dialCode": "229",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bermuda",
          "iso2": "bm",
          "dialCode": "1",
          "priority": 10,
          "areaCodes": [
              "441"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bhutan",
          "iso2": "bt",
          "dialCode": "975",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bolivia",
          "iso2": "bo",
          "dialCode": "591",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bosnia & Herzegovina",
          "iso2": "ba",
          "dialCode": "387",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Botswana",
          "iso2": "bw",
          "dialCode": "267",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Brazil",
          "iso2": "br",
          "dialCode": "55",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "British Indian Ocean Territory",
          "iso2": "io",
          "dialCode": "246",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "British Virgin Islands",
          "iso2": "vg",
          "dialCode": "1",
          "priority": 11,
          "areaCodes": [
              "284"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Brunei",
          "iso2": "bn",
          "dialCode": "673",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Bulgaria",
          "iso2": "bg",
          "dialCode": "359",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Burkina Faso",
          "iso2": "bf",
          "dialCode": "226",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Burundi",
          "iso2": "bi",
          "dialCode": "257",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cambodia",
          "iso2": "kh",
          "dialCode": "855",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cameroon",
          "iso2": "cm",
          "dialCode": "237",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Canada",
          "iso2": "ca",
          "dialCode": "1",
          "priority": 1,
          "areaCodes": [
              "204",
              "226",
              "236",
              "249",
              "250",
              "263",
              "289",
              "306",
              "343",
              "354",
              "365",
              "367",
              "368",
              "382",
              "387",
              "403",
              "416",
              "418",
              "428",
              "431",
              "437",
              "438",
              "450",
              "584",
              "468",
              "474",
              "506",
              "514",
              "519",
              "548",
              "579",
              "581",
              "584",
              "587",
              "604",
              "613",
              "639",
              "647",
              "672",
              "683",
              "705",
              "709",
              "742",
              "753",
              "778",
              "780",
              "782",
              "807",
              "819",
              "825",
              "867",
              "873",
              "879",
              "902",
              "905"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cape Verde",
          "iso2": "cv",
          "dialCode": "238",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Caribbean Netherlands",
          "iso2": "bq",
          "dialCode": "599",
          "priority": 1,
          "areaCodes": [
              "3",
              "4",
              "7"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cayman Islands",
          "iso2": "ky",
          "dialCode": "1",
          "priority": 12,
          "areaCodes": [
              "345"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Central African Republic",
          "iso2": "cf",
          "dialCode": "236",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Chad",
          "iso2": "td",
          "dialCode": "235",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Chile",
          "iso2": "cl",
          "dialCode": "56",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "China",
          "iso2": "cn",
          "dialCode": "86",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Christmas Island",
          "iso2": "cx",
          "dialCode": "61",
          "priority": 2,
          "areaCodes": [
              "89164"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cocos (Keeling) Islands",
          "iso2": "cc",
          "dialCode": "61",
          "priority": 1,
          "areaCodes": [
              "89162"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Colombia",
          "iso2": "co",
          "dialCode": "57",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Comoros",
          "iso2": "km",
          "dialCode": "269",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Congo - Brazzaville",
          "iso2": "cg",
          "dialCode": "242",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Congo - Kinshasa",
          "iso2": "cd",
          "dialCode": "243",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cook Islands",
          "iso2": "ck",
          "dialCode": "682",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Costa Rica",
          "iso2": "cr",
          "dialCode": "506",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Côte d’Ivoire",
          "iso2": "ci",
          "dialCode": "225",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Croatia",
          "iso2": "hr",
          "dialCode": "385",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cuba",
          "iso2": "cu",
          "dialCode": "53",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Curaçao",
          "iso2": "cw",
          "dialCode": "599",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Cyprus",
          "iso2": "cy",
          "dialCode": "357",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Czechia",
          "iso2": "cz",
          "dialCode": "420",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Denmark",
          "iso2": "dk",
          "dialCode": "45",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Djibouti",
          "iso2": "dj",
          "dialCode": "253",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Dominica",
          "iso2": "dm",
          "dialCode": "1",
          "priority": 13,
          "areaCodes": [
              "767"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Dominican Republic",
          "iso2": "do",
          "dialCode": "1",
          "priority": 2,
          "areaCodes": [
              "809",
              "829",
              "849"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ecuador",
          "iso2": "ec",
          "dialCode": "593",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Egypt",
          "iso2": "eg",
          "dialCode": "20",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "El Salvador",
          "iso2": "sv",
          "dialCode": "503",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Equatorial Guinea",
          "iso2": "gq",
          "dialCode": "240",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Eritrea",
          "iso2": "er",
          "dialCode": "291",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Estonia",
          "iso2": "ee",
          "dialCode": "372",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Eswatini",
          "iso2": "sz",
          "dialCode": "268",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ethiopia",
          "iso2": "et",
          "dialCode": "251",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Falkland Islands",
          "iso2": "fk",
          "dialCode": "500",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Faroe Islands",
          "iso2": "fo",
          "dialCode": "298",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Fiji",
          "iso2": "fj",
          "dialCode": "679",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Finland",
          "iso2": "fi",
          "dialCode": "358",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "France",
          "iso2": "fr",
          "dialCode": "33",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "French Guiana",
          "iso2": "gf",
          "dialCode": "594",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "French Polynesia",
          "iso2": "pf",
          "dialCode": "689",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Gabon",
          "iso2": "ga",
          "dialCode": "241",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Gambia",
          "iso2": "gm",
          "dialCode": "220",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Georgia",
          "iso2": "ge",
          "dialCode": "995",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Germany",
          "iso2": "de",
          "dialCode": "49",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ghana",
          "iso2": "gh",
          "dialCode": "233",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Gibraltar",
          "iso2": "gi",
          "dialCode": "350",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Greece",
          "iso2": "gr",
          "dialCode": "30",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Greenland",
          "iso2": "gl",
          "dialCode": "299",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Grenada",
          "iso2": "gd",
          "dialCode": "1",
          "priority": 14,
          "areaCodes": [
              "473"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guadeloupe",
          "iso2": "gp",
          "dialCode": "590",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guam",
          "iso2": "gu",
          "dialCode": "1",
          "priority": 15,
          "areaCodes": [
              "671"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guatemala",
          "iso2": "gt",
          "dialCode": "502",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guernsey",
          "iso2": "gg",
          "dialCode": "44",
          "priority": 1,
          "areaCodes": [
              "1481",
              "7781",
              "7839",
              "7911"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guinea",
          "iso2": "gn",
          "dialCode": "224",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guinea-Bissau",
          "iso2": "gw",
          "dialCode": "245",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Guyana",
          "iso2": "gy",
          "dialCode": "592",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Haiti",
          "iso2": "ht",
          "dialCode": "509",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Honduras",
          "iso2": "hn",
          "dialCode": "504",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Hong Kong SAR China",
          "iso2": "hk",
          "dialCode": "852",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Hungary",
          "iso2": "hu",
          "dialCode": "36",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Iceland",
          "iso2": "is",
          "dialCode": "354",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "India",
          "iso2": "in",
          "dialCode": "91",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Indonesia",
          "iso2": "id",
          "dialCode": "62",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Iran",
          "iso2": "ir",
          "dialCode": "98",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Iraq",
          "iso2": "iq",
          "dialCode": "964",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ireland",
          "iso2": "ie",
          "dialCode": "353",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Isle of Man",
          "iso2": "im",
          "dialCode": "44",
          "priority": 2,
          "areaCodes": [
              "1624",
              "74576",
              "7524",
              "7924",
              "7624"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Israel",
          "iso2": "il",
          "dialCode": "972",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Italy",
          "iso2": "it",
          "dialCode": "39",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Jamaica",
          "iso2": "jm",
          "dialCode": "1",
          "priority": 4,
          "areaCodes": [
              "876",
              "658"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Japan",
          "iso2": "jp",
          "dialCode": "81",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Jersey",
          "iso2": "je",
          "dialCode": "44",
          "priority": 3,
          "areaCodes": [
              "1534",
              "7509",
              "7700",
              "7797",
              "7829",
              "7937"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Jordan",
          "iso2": "jo",
          "dialCode": "962",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kazakhstan",
          "iso2": "kz",
          "dialCode": "7",
          "priority": 1,
          "areaCodes": [
              "33",
              "7"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kenya",
          "iso2": "ke",
          "dialCode": "254",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kiribati",
          "iso2": "ki",
          "dialCode": "686",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kosovo",
          "iso2": "xk",
          "dialCode": "383",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kuwait",
          "iso2": "kw",
          "dialCode": "965",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Kyrgyzstan",
          "iso2": "kg",
          "dialCode": "996",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Laos",
          "iso2": "la",
          "dialCode": "856",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Latvia",
          "iso2": "lv",
          "dialCode": "371",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Lebanon",
          "iso2": "lb",
          "dialCode": "961",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Lesotho",
          "iso2": "ls",
          "dialCode": "266",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Liberia",
          "iso2": "lr",
          "dialCode": "231",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Libya",
          "iso2": "ly",
          "dialCode": "218",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Liechtenstein",
          "iso2": "li",
          "dialCode": "423",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Lithuania",
          "iso2": "lt",
          "dialCode": "370",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Luxembourg",
          "iso2": "lu",
          "dialCode": "352",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Macao SAR China",
          "iso2": "mo",
          "dialCode": "853",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Madagascar",
          "iso2": "mg",
          "dialCode": "261",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Malawi",
          "iso2": "mw",
          "dialCode": "265",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Malaysia",
          "iso2": "my",
          "dialCode": "60",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Maldives",
          "iso2": "mv",
          "dialCode": "960",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mali",
          "iso2": "ml",
          "dialCode": "223",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Malta",
          "iso2": "mt",
          "dialCode": "356",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Marshall Islands",
          "iso2": "mh",
          "dialCode": "692",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Martinique",
          "iso2": "mq",
          "dialCode": "596",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mauritania",
          "iso2": "mr",
          "dialCode": "222",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mauritius",
          "iso2": "mu",
          "dialCode": "230",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mayotte",
          "iso2": "yt",
          "dialCode": "262",
          "priority": 1,
          "areaCodes": [
              "269",
              "639"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mexico",
          "iso2": "mx",
          "dialCode": "52",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Micronesia",
          "iso2": "fm",
          "dialCode": "691",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Moldova",
          "iso2": "md",
          "dialCode": "373",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Monaco",
          "iso2": "mc",
          "dialCode": "377",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mongolia",
          "iso2": "mn",
          "dialCode": "976",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Montenegro",
          "iso2": "me",
          "dialCode": "382",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Montserrat",
          "iso2": "ms",
          "dialCode": "1",
          "priority": 16,
          "areaCodes": [
              "664"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Morocco",
          "iso2": "ma",
          "dialCode": "212",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Mozambique",
          "iso2": "mz",
          "dialCode": "258",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Myanmar (Burma)",
          "iso2": "mm",
          "dialCode": "95",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Namibia",
          "iso2": "na",
          "dialCode": "264",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Nauru",
          "iso2": "nr",
          "dialCode": "674",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Nepal",
          "iso2": "np",
          "dialCode": "977",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Netherlands",
          "iso2": "nl",
          "dialCode": "31",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "New Caledonia",
          "iso2": "nc",
          "dialCode": "687",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "New Zealand",
          "iso2": "nz",
          "dialCode": "64",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Nicaragua",
          "iso2": "ni",
          "dialCode": "505",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Niger",
          "iso2": "ne",
          "dialCode": "227",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Nigeria",
          "iso2": "ng",
          "dialCode": "234",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Niue",
          "iso2": "nu",
          "dialCode": "683",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Norfolk Island",
          "iso2": "nf",
          "dialCode": "672",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "North Korea",
          "iso2": "kp",
          "dialCode": "850",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "North Macedonia",
          "iso2": "mk",
          "dialCode": "389",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Northern Mariana Islands",
          "iso2": "mp",
          "dialCode": "1",
          "priority": 17,
          "areaCodes": [
              "670"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Norway",
          "iso2": "no",
          "dialCode": "47",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Oman",
          "iso2": "om",
          "dialCode": "968",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Pakistan",
          "iso2": "pk",
          "dialCode": "92",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Palau",
          "iso2": "pw",
          "dialCode": "680",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Palestinian Territories",
          "iso2": "ps",
          "dialCode": "970",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Panama",
          "iso2": "pa",
          "dialCode": "507",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Papua New Guinea",
          "iso2": "pg",
          "dialCode": "675",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Paraguay",
          "iso2": "py",
          "dialCode": "595",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Peru",
          "iso2": "pe",
          "dialCode": "51",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Philippines",
          "iso2": "ph",
          "dialCode": "63",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Poland",
          "iso2": "pl",
          "dialCode": "48",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Portugal",
          "iso2": "pt",
          "dialCode": "351",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Puerto Rico",
          "iso2": "pr",
          "dialCode": "1",
          "priority": 3,
          "areaCodes": [
              "787",
              "939"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Qatar",
          "iso2": "qa",
          "dialCode": "974",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Réunion",
          "iso2": "re",
          "dialCode": "262",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Romania",
          "iso2": "ro",
          "dialCode": "40",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Russia",
          "iso2": "ru",
          "dialCode": "7",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Rwanda",
          "iso2": "rw",
          "dialCode": "250",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Samoa",
          "iso2": "ws",
          "dialCode": "685",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "San Marino",
          "iso2": "sm",
          "dialCode": "378",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "São Tomé & Príncipe",
          "iso2": "st",
          "dialCode": "239",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Saudi Arabia",
          "iso2": "sa",
          "dialCode": "966",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Senegal",
          "iso2": "sn",
          "dialCode": "221",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Serbia",
          "iso2": "rs",
          "dialCode": "381",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Seychelles",
          "iso2": "sc",
          "dialCode": "248",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Sierra Leone",
          "iso2": "sl",
          "dialCode": "232",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Singapore",
          "iso2": "sg",
          "dialCode": "65",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Sint Maarten",
          "iso2": "sx",
          "dialCode": "1",
          "priority": 21,
          "areaCodes": [
              "721"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Slovakia",
          "iso2": "sk",
          "dialCode": "421",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Slovenia",
          "iso2": "si",
          "dialCode": "386",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Solomon Islands",
          "iso2": "sb",
          "dialCode": "677",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Somalia",
          "iso2": "so",
          "dialCode": "252",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "South Africa",
          "iso2": "za",
          "dialCode": "27",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "South Korea",
          "iso2": "kr",
          "dialCode": "82",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "South Sudan",
          "iso2": "ss",
          "dialCode": "211",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Spain",
          "iso2": "es",
          "dialCode": "34",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Sri Lanka",
          "iso2": "lk",
          "dialCode": "94",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Barthélemy",
          "iso2": "bl",
          "dialCode": "590",
          "priority": 1,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Helena",
          "iso2": "sh",
          "dialCode": "290",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Kitts & Nevis",
          "iso2": "kn",
          "dialCode": "1",
          "priority": 18,
          "areaCodes": [
              "869"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Lucia",
          "iso2": "lc",
          "dialCode": "1",
          "priority": 19,
          "areaCodes": [
              "758"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Martin",
          "iso2": "mf",
          "dialCode": "590",
          "priority": 2,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Pierre & Miquelon",
          "iso2": "pm",
          "dialCode": "508",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "St. Vincent & Grenadines",
          "iso2": "vc",
          "dialCode": "1",
          "priority": 20,
          "areaCodes": [
              "784"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Sudan",
          "iso2": "sd",
          "dialCode": "249",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Suriname",
          "iso2": "sr",
          "dialCode": "597",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Svalbard & Jan Mayen",
          "iso2": "sj",
          "dialCode": "47",
          "priority": 1,
          "areaCodes": [
              "79"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Sweden",
          "iso2": "se",
          "dialCode": "46",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Switzerland",
          "iso2": "ch",
          "dialCode": "41",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Syria",
          "iso2": "sy",
          "dialCode": "963",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Taiwan",
          "iso2": "tw",
          "dialCode": "886",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tajikistan",
          "iso2": "tj",
          "dialCode": "992",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tanzania",
          "iso2": "tz",
          "dialCode": "255",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Thailand",
          "iso2": "th",
          "dialCode": "66",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Timor-Leste",
          "iso2": "tl",
          "dialCode": "670",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Togo",
          "iso2": "tg",
          "dialCode": "228",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tokelau",
          "iso2": "tk",
          "dialCode": "690",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tonga",
          "iso2": "to",
          "dialCode": "676",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Trinidad & Tobago",
          "iso2": "tt",
          "dialCode": "1",
          "priority": 22,
          "areaCodes": [
              "868"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tunisia",
          "iso2": "tn",
          "dialCode": "216",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Turkey",
          "iso2": "tr",
          "dialCode": "90",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Turkmenistan",
          "iso2": "tm",
          "dialCode": "993",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Turks & Caicos Islands",
          "iso2": "tc",
          "dialCode": "1",
          "priority": 23,
          "areaCodes": [
              "649"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Tuvalu",
          "iso2": "tv",
          "dialCode": "688",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "U.S. Virgin Islands",
          "iso2": "vi",
          "dialCode": "1",
          "priority": 24,
          "areaCodes": [
              "340"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Uganda",
          "iso2": "ug",
          "dialCode": "256",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Ukraine",
          "iso2": "ua",
          "dialCode": "380",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "United Arab Emirates",
          "iso2": "ae",
          "dialCode": "971",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "United Kingdom",
          "iso2": "gb",
          "dialCode": "44",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "United States",
          "iso2": "us",
          "dialCode": "1",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Uruguay",
          "iso2": "uy",
          "dialCode": "598",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Uzbekistan",
          "iso2": "uz",
          "dialCode": "998",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Vanuatu",
          "iso2": "vu",
          "dialCode": "678",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Vatican City",
          "iso2": "va",
          "dialCode": "39",
          "priority": 1,
          "areaCodes": [
              "06698"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Venezuela",
          "iso2": "ve",
          "dialCode": "58",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Vietnam",
          "iso2": "vn",
          "dialCode": "84",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Wallis & Futuna",
          "iso2": "wf",
          "dialCode": "681",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Western Sahara",
          "iso2": "eh",
          "dialCode": "212",
          "priority": 1,
          "areaCodes": [
              "5288",
              "5289"
          ],
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Yemen",
          "iso2": "ye",
          "dialCode": "967",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Zambia",
          "iso2": "zm",
          "dialCode": "260",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      },
      {
          "name": "Zimbabwe",
          "iso2": "zw",
          "dialCode": "263",
          "priority": 0,
          "areaCodes": null,
          "nodeById": {
              "0": {}
          }
      }
  ],
  "dialCodes": {
      "1": true,
      "7": true,
      "20": true,
      "27": true,
      "30": true,
      "31": true,
      "32": true,
      "33": true,
      "34": true,
      "36": true,
      "39": true,
      "40": true,
      "41": true,
      "43": true,
      "44": true,
      "45": true,
      "46": true,
      "47": true,
      "48": true,
      "49": true,
      "51": true,
      "52": true,
      "53": true,
      "54": true,
      "55": true,
      "56": true,
      "57": true,
      "58": true,
      "60": true,
      "61": true,
      "62": true,
      "63": true,
      "64": true,
      "65": true,
      "66": true,
      "81": true,
      "82": true,
      "84": true,
      "86": true,
      "90": true,
      "91": true,
      "92": true,
      "93": true,
      "94": true,
      "95": true,
      "98": true,
      "211": true,
      "212": true,
      "213": true,
      "216": true,
      "218": true,
      "220": true,
      "221": true,
      "222": true,
      "223": true,
      "224": true,
      "225": true,
      "226": true,
      "227": true,
      "228": true,
      "229": true,
      "230": true,
      "231": true,
      "232": true,
      "233": true,
      "234": true,
      "235": true,
      "236": true,
      "237": true,
      "238": true,
      "239": true,
      "240": true,
      "241": true,
      "242": true,
      "243": true,
      "244": true,
      "245": true,
      "246": true,
      "247": true,
      "248": true,
      "249": true,
      "250": true,
      "251": true,
      "252": true,
      "253": true,
      "254": true,
      "255": true,
      "256": true,
      "257": true,
      "258": true,
      "260": true,
      "261": true,
      "262": true,
      "263": true,
      "264": true,
      "265": true,
      "266": true,
      "267": true,
      "268": true,
      "269": true,
      "290": true,
      "291": true,
      "297": true,
      "298": true,
      "299": true,
      "350": true,
      "351": true,
      "352": true,
      "353": true,
      "354": true,
      "355": true,
      "356": true,
      "357": true,
      "358": true,
      "359": true,
      "370": true,
      "371": true,
      "372": true,
      "373": true,
      "374": true,
      "375": true,
      "376": true,
      "377": true,
      "378": true,
      "380": true,
      "381": true,
      "382": true,
      "383": true,
      "385": true,
      "386": true,
      "387": true,
      "389": true,
      "420": true,
      "421": true,
      "423": true,
      "500": true,
      "501": true,
      "502": true,
      "503": true,
      "504": true,
      "505": true,
      "506": true,
      "507": true,
      "508": true,
      "509": true,
      "590": true,
      "591": true,
      "592": true,
      "593": true,
      "594": true,
      "595": true,
      "596": true,
      "597": true,
      "598": true,
      "599": true,
      "670": true,
      "672": true,
      "673": true,
      "674": true,
      "675": true,
      "676": true,
      "677": true,
      "678": true,
      "679": true,
      "680": true,
      "681": true,
      "682": true,
      "683": true,
      "685": true,
      "686": true,
      "687": true,
      "688": true,
      "689": true,
      "690": true,
      "691": true,
      "692": true,
      "850": true,
      "852": true,
      "853": true,
      "855": true,
      "856": true,
      "880": true,
      "886": true,
      "960": true,
      "961": true,
      "962": true,
      "963": true,
      "964": true,
      "965": true,
      "966": true,
      "967": true,
      "968": true,
      "970": true,
      "971": true,
      "972": true,
      "973": true,
      "974": true,
      "975": true,
      "976": true,
      "977": true,
      "992": true,
      "993": true,
      "994": true,
      "995": true,
      "996": true,
      "998": true
  },
  "dialCodeMaxLen": 7,
  "dialCodeToIso2Map": {
      "1": [
          "us",
          "ca",
          "do",
          "pr",
          "jm",
          "as",
          "ai",
          "ag",
          "bs",
          "bb",
          "bm",
          "vg",
          "ky",
          "dm",
          "gd",
          "gu",
          "ms",
          "mp",
          "kn",
          "lc",
          "vc",
          "sx",
          "tt",
          "tc",
          "vi"
      ],
      "7": [
          "ru",
          "kz"
      ],
      "12": [
          "us",
          "ai",
          "ag",
          "bs",
          "bb",
          "vg",
          "ca"
      ],
      "13": [
          "us",
          "ca",
          "ky",
          "vi"
      ],
      "14": [
          "us",
          "bm",
          "ca",
          "gd"
      ],
      "15": [
          "us",
          "ca"
      ],
      "16": [
          "us",
          "as",
          "ca",
          "gu",
          "jm",
          "ms",
          "mp",
          "tc"
      ],
      "17": [
          "us",
          "ca",
          "dm",
          "pr",
          "sx",
          "lc",
          "vc"
      ],
      "18": [
          "us",
          "ca",
          "do",
          "jm",
          "kn",
          "tt"
      ],
      "19": [
          "us",
          "ca",
          "pr"
      ],
      "20": [
          "eg"
      ],
      "27": [
          "za"
      ],
      "30": [
          "gr"
      ],
      "31": [
          "nl"
      ],
      "32": [
          "be"
      ],
      "33": [
          "fr"
      ],
      "34": [
          "es"
      ],
      "36": [
          "hu"
      ],
      "39": [
          "it",
          "va"
      ],
      "40": [
          "ro"
      ],
      "41": [
          "ch"
      ],
      "43": [
          "at"
      ],
      "44": [
          "gb",
          "gg",
          "im",
          "je"
      ],
      "45": [
          "dk"
      ],
      "46": [
          "se"
      ],
      "47": [
          "no",
          "sj"
      ],
      "48": [
          "pl"
      ],
      "49": [
          "de"
      ],
      "51": [
          "pe"
      ],
      "52": [
          "mx"
      ],
      "53": [
          "cu"
      ],
      "54": [
          "ar"
      ],
      "55": [
          "br"
      ],
      "56": [
          "cl"
      ],
      "57": [
          "co"
      ],
      "58": [
          "ve"
      ],
      "60": [
          "my"
      ],
      "61": [
          "au",
          "cc",
          "cx"
      ],
      "62": [
          "id"
      ],
      "63": [
          "ph"
      ],
      "64": [
          "nz"
      ],
      "65": [
          "sg"
      ],
      "66": [
          "th"
      ],
      "73": [
          "ru",
          "kz"
      ],
      "77": [
          "kz"
      ],
      "81": [
          "jp"
      ],
      "82": [
          "kr"
      ],
      "84": [
          "vn"
      ],
      "86": [
          "cn"
      ],
      "90": [
          "tr"
      ],
      "91": [
          "in"
      ],
      "92": [
          "pk"
      ],
      "93": [
          "af"
      ],
      "94": [
          "lk"
      ],
      "95": [
          "mm"
      ],
      "98": [
          "ir"
      ],
      "120": [
          "us",
          "ca"
      ],
      "122": [
          "us",
          "ca"
      ],
      "123": [
          "us",
          "ca"
      ],
      "124": [
          "us",
          "bs",
          "bb",
          "ca"
      ],
      "125": [
          "us",
          "ca"
      ],
      "126": [
          "us",
          "ai",
          "ag",
          "ca"
      ],
      "128": [
          "us",
          "vg",
          "ca"
      ],
      "130": [
          "us",
          "ca"
      ],
      "134": [
          "us",
          "ca",
          "ky",
          "vi"
      ],
      "135": [
          "us",
          "ca"
      ],
      "136": [
          "us",
          "ca"
      ],
      "138": [
          "us",
          "ca"
      ],
      "140": [
          "us",
          "ca"
      ],
      "141": [
          "us",
          "ca"
      ],
      "142": [
          "us",
          "ca"
      ],
      "143": [
          "us",
          "ca"
      ],
      "144": [
          "us",
          "bm"
      ],
      "145": [
          "us",
          "ca"
      ],
      "146": [
          "us",
          "ca"
      ],
      "147": [
          "us",
          "ca",
          "gd"
      ],
      "150": [
          "us",
          "ca"
      ],
      "151": [
          "us",
          "ca"
      ],
      "154": [
          "us",
          "ca"
      ],
      "157": [
          "us",
          "ca"
      ],
      "158": [
          "us",
          "ca"
      ],
      "160": [
          "us",
          "ca"
      ],
      "161": [
          "us",
          "ca"
      ],
      "163": [
          "us",
          "ca"
      ],
      "164": [
          "us",
          "ca",
          "tc"
      ],
      "165": [
          "us",
          "jm"
      ],
      "166": [
          "us",
          "ms"
      ],
      "167": [
          "us",
          "ca",
          "gu",
          "mp"
      ],
      "168": [
          "us",
          "as",
          "ca"
      ],
      "170": [
          "us",
          "ca"
      ],
      "172": [
          "us",
          "sx"
      ],
      "174": [
          "us",
          "ca"
      ],
      "175": [
          "us",
          "ca",
          "lc"
      ],
      "176": [
          "us",
          "dm"
      ],
      "177": [
          "us",
          "ca"
      ],
      "178": [
          "us",
          "ca",
          "pr",
          "vc"
      ],
      "180": [
          "us",
          "ca",
          "do"
      ],
      "181": [
          "us",
          "ca"
      ],
      "182": [
          "us",
          "ca",
          "do"
      ],
      "184": [
          "us",
          "do"
      ],
      "186": [
          "us",
          "ca",
          "kn",
          "tt"
      ],
      "187": [
          "us",
          "ca",
          "jm"
      ],
      "190": [
          "us",
          "ca"
      ],
      "193": [
          "us",
          "pr"
      ],
      "211": [
          "ss"
      ],
      "212": [
          "ma",
          "eh"
      ],
      "213": [
          "dz"
      ],
      "216": [
          "tn"
      ],
      "218": [
          "ly"
      ],
      "220": [
          "gm"
      ],
      "221": [
          "sn"
      ],
      "222": [
          "mr"
      ],
      "223": [
          "ml"
      ],
      "224": [
          "gn"
      ],
      "225": [
          "ci"
      ],
      "226": [
          "bf"
      ],
      "227": [
          "ne"
      ],
      "228": [
          "tg"
      ],
      "229": [
          "bj"
      ],
      "230": [
          "mu"
      ],
      "231": [
          "lr"
      ],
      "232": [
          "sl"
      ],
      "233": [
          "gh"
      ],
      "234": [
          "ng"
      ],
      "235": [
          "td"
      ],
      "236": [
          "cf"
      ],
      "237": [
          "cm"
      ],
      "238": [
          "cv"
      ],
      "239": [
          "st"
      ],
      "240": [
          "gq"
      ],
      "241": [
          "ga"
      ],
      "242": [
          "cg"
      ],
      "243": [
          "cd"
      ],
      "244": [
          "ao"
      ],
      "245": [
          "gw"
      ],
      "246": [
          "io"
      ],
      "247": [
          "ac"
      ],
      "248": [
          "sc"
      ],
      "249": [
          "sd"
      ],
      "250": [
          "rw"
      ],
      "251": [
          "et"
      ],
      "252": [
          "so"
      ],
      "253": [
          "dj"
      ],
      "254": [
          "ke"
      ],
      "255": [
          "tz"
      ],
      "256": [
          "ug"
      ],
      "257": [
          "bi"
      ],
      "258": [
          "mz"
      ],
      "260": [
          "zm"
      ],
      "261": [
          "mg"
      ],
      "262": [
          "re",
          "yt"
      ],
      "263": [
          "zw"
      ],
      "264": [
          "na"
      ],
      "265": [
          "mw"
      ],
      "266": [
          "ls"
      ],
      "267": [
          "bw"
      ],
      "268": [
          "sz"
      ],
      "269": [
          "km"
      ],
      "290": [
          "sh"
      ],
      "291": [
          "er"
      ],
      "297": [
          "aw"
      ],
      "298": [
          "fo"
      ],
      "299": [
          "gl"
      ],
      "350": [
          "gi"
      ],
      "351": [
          "pt"
      ],
      "352": [
          "lu"
      ],
      "353": [
          "ie"
      ],
      "354": [
          "is"
      ],
      "355": [
          "al"
      ],
      "356": [
          "mt"
      ],
      "357": [
          "cy"
      ],
      "358": [
          "fi",
          "ax"
      ],
      "359": [
          "bg"
      ],
      "370": [
          "lt"
      ],
      "371": [
          "lv"
      ],
      "372": [
          "ee"
      ],
      "373": [
          "md"
      ],
      "374": [
          "am"
      ],
      "375": [
          "by"
      ],
      "376": [
          "ad"
      ],
      "377": [
          "mc"
      ],
      "378": [
          "sm"
      ],
      "380": [
          "ua"
      ],
      "381": [
          "rs"
      ],
      "382": [
          "me"
      ],
      "383": [
          "xk"
      ],
      "385": [
          "hr"
      ],
      "386": [
          "si"
      ],
      "387": [
          "ba"
      ],
      "389": [
          "mk"
      ],
      "390": [
          "it",
          "va"
      ],
      "420": [
          "cz"
      ],
      "421": [
          "sk"
      ],
      "423": [
          "li"
      ],
      "441": [
          "gb",
          "gg",
          "im",
          "je"
      ],
      "447": [
          "gb",
          "gg",
          "im",
          "je"
      ],
      "477": [
          "no",
          "sj"
      ],
      "500": [
          "fk"
      ],
      "501": [
          "bz"
      ],
      "502": [
          "gt"
      ],
      "503": [
          "sv"
      ],
      "504": [
          "hn"
      ],
      "505": [
          "ni"
      ],
      "506": [
          "cr"
      ],
      "507": [
          "pa"
      ],
      "508": [
          "pm"
      ],
      "509": [
          "ht"
      ],
      "590": [
          "gp",
          "bl",
          "mf"
      ],
      "591": [
          "bo"
      ],
      "592": [
          "gy"
      ],
      "593": [
          "ec"
      ],
      "594": [
          "gf"
      ],
      "595": [
          "py"
      ],
      "596": [
          "mq"
      ],
      "597": [
          "sr"
      ],
      "598": [
          "uy"
      ],
      "599": [
          "cw",
          "bq"
      ],
      "618": [
          "au",
          "cx",
          "cc"
      ],
      "670": [
          "tl"
      ],
      "672": [
          "nf"
      ],
      "673": [
          "bn"
      ],
      "674": [
          "nr"
      ],
      "675": [
          "pg"
      ],
      "676": [
          "to"
      ],
      "677": [
          "sb"
      ],
      "678": [
          "vu"
      ],
      "679": [
          "fj"
      ],
      "680": [
          "pw"
      ],
      "681": [
          "wf"
      ],
      "682": [
          "ck"
      ],
      "683": [
          "nu"
      ],
      "685": [
          "ws"
      ],
      "686": [
          "ki"
      ],
      "687": [
          "nc"
      ],
      "688": [
          "tv"
      ],
      "689": [
          "pf"
      ],
      "690": [
          "tk"
      ],
      "691": [
          "fm"
      ],
      "692": [
          "mh"
      ],
      "733": [
          "kz"
      ],
      "850": [
          "kp"
      ],
      "852": [
          "hk"
      ],
      "853": [
          "mo"
      ],
      "855": [
          "kh"
      ],
      "856": [
          "la"
      ],
      "880": [
          "bd"
      ],
      "886": [
          "tw"
      ],
      "960": [
          "mv"
      ],
      "961": [
          "lb"
      ],
      "962": [
          "jo"
      ],
      "963": [
          "sy"
      ],
      "964": [
          "iq"
      ],
      "965": [
          "kw"
      ],
      "966": [
          "sa"
      ],
      "967": [
          "ye"
      ],
      "968": [
          "om"
      ],
      "970": [
          "ps"
      ],
      "971": [
          "ae"
      ],
      "972": [
          "il"
      ],
      "973": [
          "bh"
      ],
      "974": [
          "qa"
      ],
      "975": [
          "bt"
      ],
      "976": [
          "mn"
      ],
      "977": [
          "np"
      ],
      "992": [
          "tj"
      ],
      "993": [
          "tm"
      ],
      "994": [
          "az"
      ],
      "995": [
          "ge"
      ],
      "996": [
          "kg"
      ],
      "998": [
          "uz"
      ],
      "1204": [
          "ca"
      ],
      "1226": [
          "ca"
      ],
      "1236": [
          "ca"
      ],
      "1242": [
          "bs"
      ],
      "1246": [
          "bb"
      ],
      "1249": [
          "ca"
      ],
      "1250": [
          "ca"
      ],
      "1263": [
          "ca"
      ],
      "1264": [
          "ai"
      ],
      "1268": [
          "ag"
      ],
      "1284": [
          "vg"
      ],
      "1289": [
          "ca"
      ],
      "1306": [
          "ca"
      ],
      "1340": [
          "vi"
      ],
      "1343": [
          "ca"
      ],
      "1345": [
          "ky"
      ],
      "1354": [
          "ca"
      ],
      "1365": [
          "ca"
      ],
      "1367": [
          "ca"
      ],
      "1368": [
          "ca"
      ],
      "1382": [
          "ca"
      ],
      "1387": [
          "ca"
      ],
      "1403": [
          "ca"
      ],
      "1416": [
          "ca"
      ],
      "1418": [
          "ca"
      ],
      "1428": [
          "ca"
      ],
      "1431": [
          "ca"
      ],
      "1437": [
          "ca"
      ],
      "1438": [
          "ca"
      ],
      "1441": [
          "bm"
      ],
      "1450": [
          "ca"
      ],
      "1468": [
          "ca"
      ],
      "1473": [
          "gd"
      ],
      "1474": [
          "ca"
      ],
      "1506": [
          "ca"
      ],
      "1514": [
          "ca"
      ],
      "1519": [
          "ca"
      ],
      "1548": [
          "ca"
      ],
      "1579": [
          "ca"
      ],
      "1581": [
          "ca"
      ],
      "1584": [
          "ca"
      ],
      "1587": [
          "ca"
      ],
      "1604": [
          "ca"
      ],
      "1613": [
          "ca"
      ],
      "1639": [
          "ca"
      ],
      "1647": [
          "ca"
      ],
      "1649": [
          "tc"
      ],
      "1658": [
          "jm"
      ],
      "1664": [
          "ms"
      ],
      "1670": [
          "mp"
      ],
      "1671": [
          "gu"
      ],
      "1672": [
          "ca"
      ],
      "1683": [
          "ca"
      ],
      "1684": [
          "as"
      ],
      "1705": [
          "ca"
      ],
      "1709": [
          "ca"
      ],
      "1721": [
          "sx"
      ],
      "1742": [
          "ca"
      ],
      "1753": [
          "ca"
      ],
      "1758": [
          "lc"
      ],
      "1767": [
          "dm"
      ],
      "1778": [
          "ca"
      ],
      "1780": [
          "ca"
      ],
      "1782": [
          "ca"
      ],
      "1784": [
          "vc"
      ],
      "1787": [
          "pr"
      ],
      "1807": [
          "ca"
      ],
      "1809": [
          "do"
      ],
      "1819": [
          "ca"
      ],
      "1825": [
          "ca"
      ],
      "1829": [
          "do"
      ],
      "1849": [
          "do"
      ],
      "1867": [
          "ca"
      ],
      "1868": [
          "tt"
      ],
      "1869": [
          "kn"
      ],
      "1873": [
          "ca"
      ],
      "1876": [
          "jm"
      ],
      "1879": [
          "ca"
      ],
      "1902": [
          "ca"
      ],
      "1905": [
          "ca"
      ],
      "1939": [
          "pr"
      ],
      "2125": [
          "ma",
          "eh"
      ],
      "2622": [
          "re",
          "yt"
      ],
      "2626": [
          "re",
          "yt"
      ],
      "3581": [
          "fi",
          "ax"
      ],
      "3906": [
          "it",
          "va"
      ],
      "4414": [
          "gb",
          "gg"
      ],
      "4415": [
          "gb",
          "je"
      ],
      "4416": [
          "gb",
          "im"
      ],
      "4474": [
          "gb",
          "im"
      ],
      "4475": [
          "gb",
          "im",
          "je"
      ],
      "4476": [
          "gb",
          "im"
      ],
      "4477": [
          "gb",
          "gg",
          "je"
      ],
      "4478": [
          "gb",
          "gg",
          "je"
      ],
      "4479": [
          "gb",
          "gg",
          "im",
          "je"
      ],
      "4779": [
          "sj"
      ],
      "5993": [
          "bq"
      ],
      "5994": [
          "bq"
      ],
      "5997": [
          "bq"
      ],
      "6189": [
          "au",
          "cx",
          "cc"
      ],
      "21252": [
          "ma",
          "eh"
      ],
      "26226": [
          "re",
          "yt"
      ],
      "26263": [
          "re",
          "yt"
      ],
      "35818": [
          "ax"
      ],
      "39066": [
          "it",
          "va"
      ],
      "44148": [
          "gb",
          "gg"
      ],
      "44153": [
          "gb",
          "je"
      ],
      "44162": [
          "gb",
          "im"
      ],
      "44745": [
          "gb",
          "im"
      ],
      "44750": [
          "gb",
          "je"
      ],
      "44752": [
          "gb",
          "im"
      ],
      "44762": [
          "gb",
          "im"
      ],
      "44770": [
          "gb",
          "je"
      ],
      "44778": [
          "gb",
          "gg"
      ],
      "44779": [
          "gb",
          "je"
      ],
      "44782": [
          "gb",
          "je"
      ],
      "44783": [
          "gb",
          "gg"
      ],
      "44791": [
          "gb",
          "gg"
      ],
      "44792": [
          "gb",
          "im"
      ],
      "44793": [
          "gb",
          "je"
      ],
      "61891": [
          "au",
          "cx",
          "cc"
      ],
      "212528": [
          "ma",
          "eh"
      ],
      "262269": [
          "yt"
      ],
      "262639": [
          "yt"
      ],
      "390669": [
          "it",
          "va"
      ],
      "441481": [
          "gg"
      ],
      "441534": [
          "je"
      ],
      "441624": [
          "im"
      ],
      "447457": [
          "gb",
          "im"
      ],
      "447509": [
          "je"
      ],
      "447524": [
          "im"
      ],
      "447624": [
          "im"
      ],
      "447700": [
          "je"
      ],
      "447781": [
          "gg"
      ],
      "447797": [
          "je"
      ],
      "447829": [
          "je"
      ],
      "447839": [
          "gg"
      ],
      "447911": [
          "gg"
      ],
      "447924": [
          "im"
      ],
      "447937": [
          "je"
      ],
      "618916": [
          "au",
          "cx",
          "cc"
      ],
      "2125288": [
          "eh"
      ],
      "2125289": [
          "eh"
      ],
      "3906698": [
          "va"
      ],
      "4474576": [
          "im"
      ],
      "6189162": [
          "cc"
      ],
      "6189164": [
          "cx"
      ]
  },
  "countryContainer": {},
  "selectedCountry": {},
  "selectedCountryInner": {},
  "selectedCountryA11yText": {},
  "dropdownArrow": {},
  "selectedDialCode": {},
  "dropdownContent": {},
  "searchInput": {},
  "searchResultsA11yText": {},
  "countryList": {},
  "defaultCountry": "ua"
}