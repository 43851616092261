<template>
    <!-- SECTION-PROCESS -->
    <section class="why-us" id="works_process">
      <div class="container">
        <div class="section-title">
          <h2>Here's how our process works:</h2>
        </div>
        <div class="why-us-inner">
          <div v-for="point in processData" :key="point.id" class="why-us-point">
            <div class="why-us-point-title">{{ point.title }}</div>
            <div class="why-us-point-text">
              {{ point.text }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END SECTION-PROCESS -->
  </template>
  
  <script>
  // import axios from 'axios';
  
  export default {
    name: 'ProcessComponent',
    data() {
    return {
      processData: [],
      error: null,
    };
  },
  methods: {
    fetchProcessData() {
      // Замість запиту, використовуємо статичні дані
      this.processData = [
        {
          id: 1,
          title: "Review Your Case",
          text: "Our team of dedicated experts will thoroughly examine the details of your case to assess the potential for a successful recovery. Drawing from our extensive experience with similar situations, we will provide you with an honest evaluation and strategic recommendations.",
        },
        {
          id: 2,
          title: "Collect Evidence",
          text: "We will meticulously gather all relevant information and documentation needed to build a compelling case for the recovery of your funds. This includes tracking digital footprints, collecting transaction records, and obtaining any other pertinent evidence.",
        },
        {
          id: 3,
          title: "Identify Those Responsible",
          text: "Utilizing advanced investigative techniques and state-of-the-art resources, we will identify the individuals and entities responsible for the scam. Our goal is to provide you with actionable intelligence that enables you to pursue legal action or direct compensation.",
        },
        {
          id: 4,
          title: "Develop a Recovery Strategy",
          text: "Based on the collected evidence and identified perpetrators, we will devise a comprehensive recovery strategy tailored to your specific case. This plan will outline the steps necessary to reclaim your funds, whether through direct engagement with the scammers or legal channels.",
        },
      ];
      console.log('Static process data:', this.processData);
    },
  },
  mounted() {
    this.fetchProcessData(); // Викликаємо метод для ініціалізації даних
  },
};
</script>
  