<template>
  <section class="types-of-scams scams" id="type_of_scams">
    <div class="container">
      <div class="section-title">
        <h2>Types of scams</h2>
      </div>
      <carousel
        class="carousel scams-container"
        :items-to-show="3"
        :items-to-scroll="1"
        :breakpoints="breakpoints"
        navigation
      >
        <slide v-for="(scam, index) in scams" :key="index">
          <div class="scam">
            <div href="#" class="slick-slide slick-active">
              <img :src="scam.scam_ico_url" :alt="scam.title" />
              <div class="scam-title">{{ scam.title }}</div>
              <div class="scam-text">{{ scam.text }}</div>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
// import axios from 'axios';

export default {
  name: 'TypesOfScams',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      breakpoints: {
        1200: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        576: {
          itemsToShow: 2,
          itemsToScroll: 1,
        },
        320: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
      },

      scams: [
        {
          title: "Fake Purchase",
          text: "Payment for goods/services you never received.",
          scam_ico_url: require('@/img/scams/a-fake-purchase.svg'),
        },
        {
          title: "Unauthorized Chargebacks",
          text: "When transactions are made without your explicit consent.",
          scam_ico_url: require('@/img/scams/unauthorized-chargebacks.svg'),
        },
        {
          title: "Extortion",
          text: "Fraudsters demand payment by threatening to send intimate compromising media or private information to your co-workers, family, and friends.",
          scam_ico_url: require('@/img/scams/extortion.svg'),
        },
        {
          title: "Remote Job Offers",
          text: "Offers for work-from-home opportunities by completing simple tasks that initially promise monetary returns but later request you to transfer money.",
          scam_ico_url: require('@/img/scams/remote-work-office.svg'),
        },
        {
          title: "Romance Scams",
          text: "Individuals initiating fake online relationships to deceive you.",
          scam_ico_url: require('@/img/scams/romance-scams.svg'),
        },
        {
          title: "Cryptocurrency Frauds",
          text: "Fake brokers or advisors posing as experts in the cryptocurrency market.",
          scam_ico_url: require('@/img/scams/crypto-scams.svg'),
        },
        {
          title: "Investment Scams",
          text: "Enticing but ultimately fraudulent investments.",
          scam_ico_url: require('@/img/scams/investment-scams.svg'),
        },
      ],
    };
  },
};
</script>
