<template>
  <section class="types-of-scams scams back" id="testimonials">
    <div class="container">
      <div class="section-title">
        <h2>
          Client 
          <span>Testimonials</span>
        </h2>
        <div class="subtitle">
          Explore real-life stories from clients who have successfully recovered their losses with Recovery Assist. Learn about the impactful difference we've made in the lives of those affected by financial scams.
        </div>
      </div>
      <carousel
        class="carousel scams-container"
        :items-to-show="3"
        :items-to-scroll="1"
        :breakpoints="breakpoints"
        navigation
      >
        <slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="ts-body">
            <div class="ts-inner">
              <div class="ts-stars">
                <img :src="stars_img" alt="Rating stars">
              </div>
              <div class="ts-date">
                {{ testimonial.date }}
              </div>
            </div>
            <div class="ts-comment">
              <div class="photo_name">
                <img :src="testimonial.photo" alt="Client photo" class="ts-photo" />
                <div class="ts-name">{{ testimonial.name }}</div>
              </div>
              <div class="ts-title">{{ testimonial.title }}</div>
              <div class="ts-text">{{ testimonial.text }}</div>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
  name: 'TypesOfScams',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      stars_img: require('@/img/5stars.png'),
      testimonials: [],
      breakpoints: {
        1200: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        992: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        768: {
          itemsToShow: 2,
          itemsToScroll: 1,
        },
        576: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
        320: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
      },
    };
  },

  methods: {
    fetchTestimonials() {
      // Замість запиту використовуємо статичні дані
      this.testimonials = [
        {
          id: 7,
          name: "John Doe",
          photo: require('@/img/people/photo1.jpg'),
          title: "Recovery Assist Made a Difference",
          text: "I turned to Recovery Assist after being scammed in cryptocurrency. My manager, David, has been thorough and helpful. We've recovered half the lost amount already and I trust they'll help with the rest. I highly recommend Recovery Assist services to scam victims.",
          date: "2024-05-07",
        },
        {
          id: 8,
          name: "Jane Smith",
          photo: require('@/img/people/photo2.jpg'),
          title: "Funds Recovered with Integrity",
          text: "After realizing that I was just one of many who had been promised millionaire status within a month, I was convinced I would never see my money again. Thanks to Recovery Assist, not only did I recover my funds, but we also managed to get back at them!",
          date: "2024-05-08",
        },
        {
          id: 9,
          name: "Mike Johnson",
          photo: require('@/img/people/photo3.jpg'),
          title: "Restoring Hope After a Devastating Scam",
          text: "Investing in Forex didn't just cost me my family's savings; it cost me my family as well. When I discovered it was a scam, I was devastated. With the help of Recovery Assist, I not only recovered my money but also regained hope for the future.",
          date: "2024-05-09",
        },
        {
          id: 10,
          name: "Emily Davis",
          photo: require('@/img/people/photo5.jpg'),
          title: "From Loss to New Beginnings",
          text: "I regret risking my own house investing in Binary Options. Losing everything forced me to move back to my parents' house with my children. Thanks to Michael and Recovery Assist, I recovered my money, and soon we'll be moving into a new home.",
          date: "2024-05-10",
        },
      ];
      console.log('Static testimonials data:', this.testimonials);
    },
  },
  mounted() {
    this.fetchTestimonials(); // Викликаємо метод для ініціалізації даних
  },
};
</script>
